* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%; // for iOS
  box-direction: normal;
  font-family: $root-font-family;
  font-size: $small-device-font-size;
  line-height: $root-line-height;
  font-weight: $root-font-weight;
}

html, body {
  margin: 0;
  padding: 0;
}

html {
  font-family: $root-font-family;
  font-size: $root-font-size;
  line-height: $root-line-height;

  @media (min-width: $large-breakpoint) {
    font-size: $large-font-size;
  }
}

body {
  color: $body-color;
  background-color: $body-bg;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

h1,
h2,
h3,
h4 {
  font-weight: $heading-font-weight;
}

h1 {
  font-size: $h1-font-size;
  line-height: $h1-line-height;
  margin-bottom: 1rem;
}

h2 {
  font-size: $h2-font-size;
  margin-bottom: .5rem;
}

h3 {
  font-size: $h3-font-size;
}

h4 {
  font-size: $h4-font-size;
}

h5 {
  font-size: $h5-font-size;
}

// Style section headers if needed
// related to highlighting the current section
// based on https://codepen.io/saas/pen/LYENgqq
h1,
h2,
h3,
h4,
h5 {
  section.visible-section & {
  }
  div.visible-section & {
  }
}

figure {
  max-width: 100%;
  margin: 0;
  padding: 0;
}

%quotebox {
  padding: .8889rem;
  margin-top: 1rem;
  margin-left: 0;
  margin-right: 0;
  //border-radius: 6px;
  border-left: 6px solid;
  border-right: 6px solid transparent;
  border-right-color: rgba(0, 0, 0, 0) transparent;
  color: #7a7a7a;
}

blockquote {
  background-color: #fafafa;
  border-left-color: #e6e6e6;
  @extend %quotebox;
}

.important {
  background-color: #fbf8e8;
  border-left-color: #fee450;
  @extend %quotebox;
}

.warning {
  background-color: #f2dbdc;
  border-left-color: #ae272f;
  @extend %quotebox;
}

kbd {
  font-family: $root-font-family;
  padding: 2px 7px;
  border: 1px solid $gray-4;
  font-size: 0.8em;
  line-height: 1.4;
  background-color: #f3f3f3;
  color: $gray-9;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 2px $white inset;
  border-radius: 3px;
  display: inline-block;
  text-shadow: 0 1px 0 $white;
  white-space: nowrap;
}

a {
  //border-bottom: 1px dotted;
  color: $link-color;
  text-decoration: none;
  word-wrap: break-word;

  &.sidebar-nav-item {
    &:hover {
      @include link-no-decoration();
    }

    &:focus {
      @include link-no-decoration();
    }
  }

  &:focus, &:hover {
    @include link-hover();

    &.tag {
      @include link-no-decoration();
    }
  }

  &.tag {
    @include link-no-decoration();
  }

  &.read-more-symbol {
    text-decoration: none;
    @include link-no-decoration();
  }

  strong {
    color: inherit;
  }
}


.content {
  padding-bottom: 2rem;
  padding-top: 2rem;
}


img {
  display: block;
  max-width: 100%;
  margin: 0 0 1rem;
  border-radius: 5px;
}

table {
  margin-bottom: 1rem;
  width: 100%;
  font-size: 85%;
  border: 1px solid #e5e5e5;
  border-collapse: collapse;
}

td,
th {
  padding: .25rem .5rem;
  border: 1px solid #e5e5e5;
}

th {
  text-align: left;
}

tbody tr:nth-child(odd) td,
tbody tr:nth-child(odd) th {
  background-color: #f9f9f9;
}

/////// arkey.fr specifics

hr {
  height: .25em;
  padding: 0;
  margin: 24px 0;
  background-color: $darker-brown;
  border: 0;
}

* {
  /* For Internet Explorer */
  scrollbar-face-color: $scrollbar-color;
  scrollbar-track-color: transparent;
  /* The emerging W3C standard
     that is currently Firefox-only */
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-color transparent;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 0.7rem;
  height: 0.7rem;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 0.4rem 0.4rem $scrollbar-color;
  border: solid 0.2rem transparent;
}

// wrapTable shortcode
.table-wrapper {
  overflow-x: auto;

  table {
    white-space: nowrap;
  }
}

// used in a 'c' (class) shortcode
.red-span {
  color: $red;
}

.highlight-red {
  background-color: rgba(169, 68, 66, 0.19);
}

.color-part-1 {
  color: $cyan;
}

.color-part-2 {
  color: $blue;
}

// mark shortcode
mark {
  background-color: $yellow-1;
  padding: 0.2rem;
}

// amzn shortcode
.amazon-shortcode {
  img {
    width: 60%;
  }
}


// landing _more_ button
.landing-more {
  margin-left: 85%;
  margin-right: 15%;
  color: $white;
  background: $tag-color;
  border: 1px solid $tag-color;
  border-radius: 0.2rem;
  width: 2rem;
  text-align: center;
  height: 1rem;

  &:hover, &:focus {
    color: $tag-color !important;
    background: transparent;
    border: 1px dotted $tag-color;

    & a {
      line-height: 1rem;
      vertical-align: top;
      text-decoration: none;
    }
  }

  & a {
    color: inherit;
    border: initial;
    line-height: 0.9rem;
    vertical-align: top;
    text-decoration: none;
  }
}


/* taxonomy navigation */
ul.tags,
ul.taxonomies,
ul.posts {
  list-style: none;

  li {
    &.taxonomy-item a::before,
    &.blog-item a::before {
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      content: '\f0a9';
      margin-left: -1.3rem;
      margin-right: 0.3rem;
      color: $link-color;

      &:hover {
        color: $link-hover-color;
      }
    }

    &.tag-item {
      display: inline-block;
    }

    .list__title--small {
      time {
        font-family: $code-font-family;
      }

      time + a {
        display: flex;
      }

      a {
        border-bottom: 1px solid transparent;

        &:focus {
          @include link-hover();
        }

        &:hover {
          @include link-hover();
        }
      }
    }
  }

  h3 {
    margin-bottom: 0.3rem;
    border-bottom: 2px solid $gray-4;
  }
}

ul.posts {
  li {
    margin-bottom: 0.5rem;
  }
}

.character-container {
  text-align: center;
  line-height: 2rem;
  font-size: 1.0rem;
  margin-top: 1rem;
  background-color: #e1edfcff;
  cursor: pointer;

  .inactive {
    color: $gray-3;
    cursor: default;
  }

  .character-element {
    margin-right: 0.3rem;
    display: inline-block;
  }
}

