pre {
  border: 1px dashed $border-color;
  border-radius: 3px;
  tab-size: 2;
  //width: 111%;
  //margin-left: -11%;
  //padding-left: 9%;
  //padding-right: 2%;
  //background: #29292e;
  overflow-x: auto;

  code {
    //white-space: pre-wrap;
  }
}

%code-font {
  font-family: $code-font-family;
  font-size: $code-font-size;
}

%code-in-rounded-box {
  border-radius: 4px;
  padding: 2px 4px;
  color: #bf616a;
  background-color: #f9f2f4;
}

%code-style-codersblock {
  padding: 2px 4px;
  border: 1px dotted #adb5db;
  background-color: $gray-1;
}

%code-style-npf {
  padding: .1em .1em;
  color: #ab5979;
  border-radius: 3px;
}

a {
  code {
    color: $link-color;
  }
}

code {
  @extend %code-font;
  @extend %code-style-npf;
}

// highlight.js
.hljs {
  font-family: $code-font-family;
  font-size: $code-fence-font-size;
  line-height: $root-line-height;
  white-space: pre;
  border: initial;
  border-radius: 4px;
}
