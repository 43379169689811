$small-title: #7a2518 !default;
$title-color: #ba3925 !default;
$title-link-hover-color: #a53221 !default;
$h1-border-color: #dddddf !default;
$h1-color: rgba(0, 0, 0, .85) !default;
$sidebar-border-color: #dbdbd6 !default;
$sidebar-bg-color: #f3f3f2 !default;
$paragraph-color: rgba(0, 0, 0, 0.85) !default;
$table-td-color: rgba(0, 0, 0, 0.8) !default;
$table-border-color: #dedede !default;
$table-alternate-bg-color: #f8f8f7 !default;
$background-color: #fff !default;
$table-header-footer-bg-color: #f7f8f7 !default;
$admonition-default-border-color: #ddddd8 !default;
$admonition-cite-default-color: rgba(0, 0, 0, 0.6) !default;
$admonition-icon-shadow-color: rgba(0, 0, 0, 0.5) !default;
$admonition-icon-tip-color: #4b70b4 !default;
$admonition-icon-tip-shadow-color: rgba(155, 155, 0, 0.8) !default;
$admonition-icon-warning-color: #bf4c00 !default;
$admonition-icon-caution-color: #bf7900 !default;
$admonition-icon-important-color: #bf0000 !default;
$admonition-icon-important-bg-color: #ffc8c8 !default;
$admonition-icon-important-border-color: #ff6767 !default;
$admonition-icon-warning-bg-color: #f9dcc8 !default;
$admonition-icon-warning-border-color: #f3aa79 !default;
$admonition-icon-caution-bg-color: #f7e5ce !default;
$admonition-icon-caution-border-color: #fab55e !default;
$conum-color: #fff !default;
$conum-bg-color: rgba(0, 0, 0, 0.8) !default;
$hlist-colist-table-bg-color: rgba(13, 13, 13, 0) !default;
$pre-color: rgba(0, 0, 0, 0.9) !default;
$pre-bg-color: #f7f7f8 !default;
$not-pre-code-bg-color: #f3f3f3 !default;
$attribution-color: rgba(229, 224, 216, 0.6) !default;
$exampleblock-border-color: rgb(72, 71, 63) !default;
$exampleblock-shadow-color: rgb(45, 46, 46) !default;
$exampleblock-bg-color: rgb(255, 254, 247) !default;
$link-color: #1d4b8f !default;
$hover-link-color: $link-color !default;
$blockquote-quote-shadow-color: rgba(0, 0, 0, 0.1) !default;

// Rely on having an `adoc` class to target specifically Asciidoc,
// see `page-single/content.html`
.post.adoc {
  text-rendering: optimizeLegibility;

  div, dl, dt, dd, ul, ol, li, h1, h2, h3, #toctitle, .sidebarblock > .content > .title, h4, h5, h6, pre, form, p, blockquote, th, td {
    margin: 0;
    padding: 0;
    direction: ltr;
  }

  p {
    margin-bottom: 1.25rem;
  }


  ul, ol {
    margin-left: 1.5em;

    li > p {
      margin: 0;
    }
  }

  ul, ol, dl {
    font-size: 1em;
    line-height: 1.6;
    margin-bottom: 1.25em;
    list-style-position: outside;
    font-family: inherit;
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 1.2;
    word-spacing: -.05rem;
    font-weight: 300;
    font-style: normal;
    color: $title-color;
    text-rendering: optimizeLegibility;
    margin-top: 1rem;
    margin-bottom: .5rem;
  }

  h1, h2, h3, #toctitle, h4, h5, h6 {
    line-height: 1.2;
    word-spacing: -.05em;
  }

  img, object, svg {
    display: inline-block;
    vertical-align: middle;
  }

  img {
    -ms-interpolation-mode: bicubic;
    max-width: 100%;
    height: auto;
  }

  object, embed {
    max-width: 100%;
    height: auto;
  }

  img {
    border: 0;
  }

  details, .audioblock, .imageblock, .literalblock, .listingblock, .stemblock, .videoblock {
    margin-bottom: 1.25em;
  }

  #header > h1:only-child {
    border-bottom: 1px solid $h1-border-color;
    padding-bottom: 8px;
  }

  #header > h1:first-child {
    color: $h1-color;
    margin-top: 2.25rem;
    margin-bottom: 0;
  }

  h1 {
    font-size: 2.125rem;
    @media screen and (min-width: 768px) {
      font-size: 2.75rem;
    }
  }

  h2 {
    font-size: 1.6875em;
    @media screen and (min-width: 768px) {
      font-size: 2.2rem;
    }
  }

  h1, h2, p, td.content, span.alt {
    letter-spacing: -.01em;
  }

  .sidebarblock {
    border-style: solid;
    border-width: 1px;
    border-color: $sidebar-border-color;
    margin-bottom: 1.25em;
    padding: 1.25em;
    background: $sidebar-bg-color;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    text-rendering: optimizeLegibility;

    .content {
      margin: 0;
      padding: 0;

      .title {
        color: $small-title;
        text-align: center;

        font-size: 1.6875rem;
        line-height: 1.2;
        word-spacing: -.05rem;
        font-weight: 300;
        font-style: normal;
      }
    }
  }

  h3, #toctitle, .sidebarblock > .content > .title {
    font-size: 1.6875rem;
    @media screen and (min-width: 768px) {
      font-size: 1.6875rem;
    }

  }

  // last child
  .exampleblock > .content > :last-child > :last-child, .exampleblock > .content .olist > ol > li:last-child > :last-child, .exampleblock > .content .ulist > ul > li:last-child > :last-child, .exampleblock > .content .qlist > ol > li:last-child > :last-child, .sidebarblock > .content > :last-child > :last-child, .sidebarblock > .content .olist > ol > li:last-child > :last-child, .sidebarblock > .content .ulist > ul > li:last-child > :last-child, .sidebarblock > .content .qlist > ol > li:last-child > :last-child {
    margin-bottom: 0;
  }


  .admonitionblock td.content > .title, .audioblock > .title, .exampleblock > .title, .imageblock > .title, .listingblock > .title, .literalblock > .title, .stemblock > .title, .openblock > .title, .paragraph > .title, .quoteblock > .title, table.tableblock > .title, .verseblock > .title, .videoblock > .title, .dlist > .title, .olist > .title, .ulist > .title, .qlist > .title, .hdlist > .title {
    text-align: left;
    font-size: 1rem;
    font-style: italic;
  }

  .subheader, .admonitionblock td.content > .title, .audioblock > .title, .exampleblock > .title, .imageblock > .title, .listingblock > .title, .literalblock > .title, .stemblock > .title, .openblock > .title, .paragraph > .title, .quoteblock > .title, table.tableblock > .title, .verseblock > .title, .videoblock > .title, .dlist > .title, .olist > .title, .ulist > .title, .qlist > .title, .hdlist > .title {
    line-height: 1.45;
    color: $small-title;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: .25rem;
  }

  .imageblock > .content {
    overflow-x: auto;
  }

  .quoteblock {
    margin: 0 1em 1.25em 1.5em;
    display: table;

    // hyde-hyde fix : pad content
    blockquote .paragraph {
      padding-left: 0.5rem;
    }

    blockquote, p {
      color: $paragraph-color;
      font-size: 1.15rem;
      line-height: 1.75;
      word-spacing: .1em;
      letter-spacing: 0;
      font-style: italic;
      text-align: justify;
    }

    blockquote::before {
      content: "\201c";
      float: left;
      font-size: 2.75em;
      font-weight: bold;
      line-height: .6em;
      margin-left: -.6em;
      color: $small-title;
      text-shadow: 0 1px 2px $blockquote-quote-shadow-color;
    }
  }

  table {
    background: $background-color;
    margin-bottom: 1.25em;
    border: solid 1px $table-border-color;

    thead, tfoot {
      background: $table-header-footer-bg-color;
    }

    thead tr {
      th, td {
        padding: .5em .625em .625em;
        font-size: inherit;
        color: $table-td-color;
        text-align: left;
      }
    }

    tfoot tr {
      th, td {
        padding: .5em .625em .625em;
        font-size: inherit;
        color: $table-td-color;
        text-align: left;
      }
    }

    tr {
      th, td {
        padding: .5625em .625em;
        font-size: inherit;
        color: $table-td-color;
      }

      &.even, &.alt {
        background: $table-alternate-bg-color;
      }
    }

    thead tr th, tfoot tr th, tbody tr td, tr td, tfoot tr td {
      display: table-cell;
      line-height: 1.6;
    }


    th.halign-left, td.halign-left {
      text-align: left;
    }

    th.halign-right, td.halign-right {
      text-align: right;
    }

    th.halign-center, td.halign-center {
      text-align: center;
    }

    th.valign-top, td.valign-top {
      vertical-align: top;
    }

    th.valign-bottom, td.valign-bottom {
      vertical-align: bottom;
    }

    th.valign-middle, td.valign-middle {
      vertical-align: middle;
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  table.tableblock {
    > .title {
      line-height: 1.45;
      color: $small-title;
      font-weight: 400;
      margin-top: 0;
      margin-bottom: .25em;
      text-rendering: optimizeLegibility;
      text-align: left;
      //font-family: "Noto Serif","DejaVu Serif",serif;
      font-size: 1rem;
      font-style: italic;
    }

    &.fit-content > caption.title {
      white-space: nowrap;
      width: 0;
    }

    #preamble > .sectionbody > [class="paragraph"]:first-of-type p {
      font-size: inherit;
    }

    max-width: 100%;
    border-collapse: separate;
  }

  p.tableblock:last-child {
    margin-bottom: 0;
  }

  td.tableblock > .content {
    margin-bottom: 1.25em;

    > :last-child {
      margin-bottom: -1.25em;
    }
  }

  table.tableblock, th.tableblock, td.tableblock {
    border: 0 solid $table-border-color;
  }

  table {
    &.grid-all > {
      thead > tr > .tableblock, tbody > tr > .tableblock {
        border-width: 0 1px 1px 0;
      }

      tfoot > tr > .tableblock {
        border-width: 1px 1px 0 0;
      }
    }

    &.grid-cols > * > tr > .tableblock {
      border-width: 0 1px 0 0;
    }

    &.grid-rows > {
      thead > tr > .tableblock, tbody > tr > .tableblock {
        border-width: 0 0 1px;
      }

      tfoot > tr > .tableblock {
        border-width: 1px 0 0;
      }
    }

    &.grid-all > * > tr > .tableblock:last-child, &.grid-cols > * > tr > .tableblock:last-child {
      border-right-width: 0;
    }

    &.grid-all > {
      tbody > tr:last-child > .tableblock, thead:last-child > tr > .tableblock {
        border-bottom-width: 0;
      }
    }

    &.grid-rows > {
      tbody > tr:last-child > .tableblock, thead:last-child > tr > .tableblock {
        border-bottom-width: 0;
      }
    }

    &.frame-all {
      border-width: 1px;
    }

    &.frame-sides {
      border-width: 0 1px;
    }

    &.frame-topbot, &.frame-ends {
      border-width: 1px 0;
    }

    &.stripes-all tr, &.stripes-odd tr:nth-of-type(odd), &.stripes-even tr:nth-of-type(even), &.stripes-hover tr:hover {
      background: $table-alternate-bg-color;
    }
  }

  p.tableblock {
    > code:only-child {
      background: none;
      padding: 0;
    }

    font-size: 1em;
  }


  .admonitionblock {
    > table {
      border-collapse: separate;
      border: 0;
      background: $background-color;
      width: 100%;

      // hyde-hyde fix : remove nth-child(2n+1)
      tbody tr td,
      tbody tr th {
        background: $background-color;
      }

      td {
        // hyde-hyde fix : remove border
        border: initial;

        &.icon {
          text-align: center;
          width: 80px;

          img {
            max-width: none;
          }

          .title {
            font-weight: bold;
            text-transform: uppercase;
          }
        }

        &.content {
          font-size: 1.0625rem;
          // hyde-hyde fix : fix default padding and margin to 0
          margin: 0;
          padding: 0 1.25rem 0 1.125rem;
          border-left: 2px solid $admonition-default-border-color;
          color: $admonition-cite-default-color;

          > :last-child > :last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    //noinspection CssNoGenericFontName
    td.icon {
      [class^="fa icon-"] {
        font-size: 2rem;
        font-family: 'Font Awesome 5 Free';
        cursor: default;
        text-shadow: 1px 1px 2px $admonition-icon-shadow-color;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
      }

      .icon-note::before {
        content: "\f05a"; // equivalent to <i class="fas fa-info-circle"></i>
        color: $admonition-icon-tip-color;
      }

      .icon-tip::before {
        font-weight: 400; // regular style/weight
        content: "\f0eb"; // equivalent to <i class="far fa-lightbulb"></i>
        text-shadow: 1px 1px 2px $admonition-icon-tip-shadow-color;
      }

      .icon-warning::before {
        content: "\f071"; // equivalent to <i class="fas fa-exclamation-triangle"></i>
        color: $admonition-icon-warning-color;
      }

      .icon-caution::before {
        font-family: "Font Awesome 5 Brands";
        content: "\f3ac"; // equivalent to <i class="fab fa-gripfire"></i>
        color: $admonition-icon-caution-color;
      }

      .icon-important::before {
        content: "\f06a"; // equivalent to <i class="fas fa-exclamation-circle"></i>
        color: $admonition-icon-important-color;
      }
    }

    // fix hyde-hyde integration
    &.important {
      border-left: none;
      background-color: $admonition-icon-important-bg-color;

      > table td.content {
        background-color: $admonition-icon-important-bg-color;
        border-left: 2px solid $admonition-icon-important-border-color;
      }
    }

    &.warning {
      border-left: none;
      background-color: $admonition-icon-warning-bg-color;

      > table td.content {
        background-color: $admonition-icon-warning-bg-color;
        border-left: 2px solid $admonition-icon-warning-border-color;
      }
    }

    &.caution {
      border-left: none;
      background-color: $admonition-icon-caution-bg-color;

      > table td.content {
        background-color: $admonition-icon-caution-bg-color;
        border-left: 2px solid $admonition-icon-caution-border-color;
      }
    }
  }

  .conum[data-value] {
    display: inline-block;
    color: $conum-color !important;
    background: $conum-bg-color;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    font-size: .75em;
    width: 1.67em;
    height: 1.67em;
    line-height: 1.67em;
    font-style: normal;
    font-weight: bold;

    * {
      color: $conum-color !important;
    }

    + b {
      display: none;
    }

    &::after {
      content: attr(data-value);
    }
  }

  pre .conum[data-value] {
    position: relative;
    top: -.125em;
  }

  b.conum * {
    color: inherit !important;
  }

  .conum:not([data-value]):empty {
    display: none;
  }


  .dlist > .title,
  .olist > .title,
  .ulist > .title,
  .qlist > .title {
    text-rendering: optimizeLegibility;
    text-align: left;
    font-size: 1rem;
    font-style: italic;
  }

  .hdlist > {
    .title {
      text-rendering: optimizeLegibility;
      text-align: left;
      font-size: 1rem;
      font-style: italic;
    }

    table {
      border: 0;
      background: none;

      tbody > tr {
        background-color: $hlist-colist-table-bg-color;
        background-image: none;
      }

      // fix hyde-hyde collision
      width: initial;

      tbody tr {
        td, th {
          border: none;
        }

        &:nth-child(2n+1) {
          td, th {
            background: inherit;
          }
        }
      }

    }
  }

  .colist {
    > table {
      border: 0;
      background: none;

      tbody > tr {
        background-color: $hlist-colist-table-bg-color;
        background-image: none;

        td, th {
          border: none;
        }

        &:nth-child(2n+1) {
          td, th {
            background: inherit;
          }
        }
      }
    }

    td:not([class]) {
      &:first-child {
        padding: .4em .75em 0;
        line-height: 1;
        vertical-align: top;
        width: 1.2rem;

        img {
          max-width: none;
        }
      }

      &:last-child {
        padding: .25em 0;
      }
    }
  }


  td {
    &.hdlist1, &.hdlist2 {
      vertical-align: top;
      padding: 0 .625em;
    }

    &.hdlist1 {
      font-weight: bold;
      padding-bottom: 1.25em;
    }
  }

  dl {
    dt {
      margin-bottom: .3125em;
      font-weight: bold;
    }

    dd {
      margin-left: 1.125em;
      margin-bottom: 1.25em;
    }
  }


  pre {
    color: $pre-color;

    font-family: "Droid Sans Mono", "DejaVu Sans Mono", monospace;
    line-height: 1.45;
    text-rendering: optimizeSpeed;

    //white-space: pre-wrap;

    > code {
      display: block;
    }

    code, pre {
      line-height: inherit;
    }
  }

  .literalblock pre, .listingblock > .content > pre {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    overflow-x: auto;
    padding: 1em;
    font-size: .8125em;
  }

  @media screen and (min-width: 768px) {
    .literalblock pre, .listingblock > .content > pre {
      font-size: .90625em;
    }
  }

  @media screen and (min-width: 1280px) {
    .literalblock pre, .listingblock > .content > pre {
      font-size: 1em;
    }
  }

  .literalblock pre {
    background: $pre-bg-color;
  }

  .listingblock > .content > pre {
    &:not(.highlight), &[class="highlight"], &[class^="highlight "] {
      background: $pre-bg-color;
    }
  }

  .literalblock.output pre {
    color: $pre-bg-color;
    background: $pre-color;
  }

  .listingblock {
    > .content {
      // removing relative position blockswitch tabs
      //position: relative;
    }

    code[data-lang]::before {
      display: none;
      content: attr(data-lang);
      position: absolute;
      font-size: .75em;
      top: .425rem;
      right: .5rem;
      line-height: 1;
      text-transform: uppercase;
      color: inherit;
      opacity: .5;
    }

    &:hover code[data-lang]::before {
      display: block;
    }

    &.terminal pre .command {
      &::before {
        content: attr(data-prompt);
        padding-right: .5em;
        color: inherit;
        opacity: .5;
      }

      &:not([data-prompt])::before {
        content: "$";
      }
    }

    pre {
      &.highlightjs {
        padding: 0;

        > code {
          padding: 1em;
          -webkit-border-radius: 4px;
          border-radius: 4px;
        }
      }

      &.prettyprint {
        border-width: 0;
      }
    }
  }

  :not(pre) {
    &:not([class^=L]) {
      > code {
        font-size: .85em;
        font-style: normal !important;
        letter-spacing: 0;
        padding: .1em .5ex;
        word-spacing: -.15em;
        background: $not-pre-code-bg-color;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        line-height: 1.45;
        text-rendering: optimizeSpeed;
        word-wrap: break-word;
      }
    }

    > code.nobreak {
      word-wrap: normal;
    }

    > code.nowrap {
      white-space: nowrap;
    }
  }

  .literalblock + .colist, .listingblock + .colist {
    margin-top: -.5em;
  }

  .verseblock {
    margin: 0 1em 1.25em;

    pre {
      padding: 0.3rem;
      font-size: 1.15rem;
      color: $paragraph-color;
      font-weight: 300;
    }
  }

  .quoteblock .attribution,
  .verseblock .attribution {
    font-size: .9375em;
    line-height: 1.45;
    font-style: italic;
  }

  .quoteblock .attribution {
    margin-top: .75em;
    margin-right: .5ex;
    text-align: right;

    cite {
      color: $attribution-color;
    }
  }

  .verseblock .attribution cite {
    color: $attribution-color;
  }

  .quoteblock .attribution cite,
  .verseblock .attribution cite {
    display: block;
    letter-spacing: -.025em;
    color: $admonition-cite-default-color;
  }


  .exampleblock > .content {
    border-color: $exampleblock-border-color;
    box-shadow: $exampleblock-shadow-color 0 1px 4px;
    background-color: $exampleblock-bg-color;
    background-image: none;
    border-style: solid;
    border-width: 1px;
    margin-bottom: 1.25em;
    padding: 1.25em;
    -webkit-border-radius: 4px;
    border-radius: 4px;
  }

  // section anchors
  a {
    color: $link-color;

    &:hover, &:focus {
      color: $hover-link-color;
    }

    &:hover {
      cursor: pointer;
    }

    &:active, &:hover {
      outline: 0;
    }
  }

  #content h1 > a.anchor, h2 > a.anchor, h3 > a.anchor, #toctitle > a.anchor, .sidebarblock > .content > .title > a.anchor, h4 > a.anchor, h5 > a.anchor, h6 > a.anchor {
    position: absolute;
    z-index: 1001;
    width: 1.5ex;
    margin-left: -1.5ex;
    display: block;
    text-decoration: none !important;
    visibility: hidden;
    text-align: center;
    font-weight: 400;
  }

  #content h1 > a.anchor::before, h2 > a.anchor::before, h3 > a.anchor::before, #toctitle > a.anchor::before, .sidebarblock > .content > .title > a.anchor::before, h4 > a.anchor::before, h5 > a.anchor::before, h6 > a.anchor::before {
    content: "\00A7"; // this char '§'
    font-size: .85em;
    display: block;
    padding-top: .1em;
  }

  #content h1 {
    &:hover > a.anchor, > a.anchor:hover {
      visibility: visible;
    }
  }

  h2 {
    &:hover > a.anchor, > a.anchor:hover {
      visibility: visible;
    }
  }

  h3:hover > a.anchor, #toctitle:hover > a.anchor, .sidebarblock > .content > .title:hover > a.anchor, h3 > a.anchor:hover, #toctitle > a.anchor:hover, .sidebarblock > .content > .title > a.anchor:hover {
    visibility: visible;
  }

  h4 {
    &:hover > a.anchor, > a.anchor:hover {
      visibility: visible;
    }
  }

  h5 {
    &:hover > a.anchor, > a.anchor:hover {
      visibility: visible;
    }
  }

  h6 {
    &:hover > a.anchor, > a.anchor:hover {
      visibility: visible;
    }
  }

  #content h1 > a.link, h2 > a.link, h3 > a.link, #toctitle > a.link, .sidebarblock > .content > .title > a.link, h4 > a.link, h5 > a.link, h6 > a.link {
    color: $title-color;
    text-decoration: none;
  }

  #content h1 > a.link:hover, h2 > a.link:hover, h3 > a.link:hover, #toctitle > a.link:hover, .sidebarblock > .content > .title > a.link:hover, h4 > a.link:hover, h5 > a.link:hover, h6 > a.link:hover {
    color: $title-link-hover-color;
    // fix hyde-hyde collision
    border-bottom: none;
  }

  details {
    padding: 0.75rem;

    & > summary:first-of-type {
      cursor: pointer;
      display: list-item;
      outline: none;
      margin-bottom: .50em;
      font-style: italic;
    }

    & > summary::marker {
      font-family: 'Font Awesome 5 Free';
      font-weight: 800;
      //content: "\f138"; // equivalent to <i class="fas fa-chevron-circle-right"></i>
      content: "\f0fe"; // equivalent to <i class="fas fa-plus-square"></i>
      font-style: normal;
    }

    // for some reasons webkit / blink need a separate rule
    // summary::-webkit-details-marker
    // and it only supports changing colors, while it's possible to overcome that via
    // :before / :after pseudo-classes it becomes a mess to handle for web compatibility
    // so well only standard browsers will use these.
    // https://developer.mozilla.org/en-US/docs/Web/CSS/::marker

    &[open] > summary::marker {
      font-family: 'Font Awesome 5 Free';
      font-weight: 800;
      //content: "\f13a"; // equivalent to <i class="fas fa-chevron-circle-down"></i>
      content: "\f146"; // equivalent to <i class="fas fa-minus-square"></i>
      font-style: normal;
    }
  }


  /*colors*/
  .aqua {color: rgb(185, 230, 202)}
  .aqua-background {background-color: rgb(116, 178, 158)}
  .black {color: rgb(12, 12, 10)}
  .black-background {background-color: rgb(30, 28, 25)}
  .blue {color: rgb(150, 161, 180)}
  .blue-background {background-color: rgb(39, 36, 117);}
  .fuchsia {color: rgb(222, 145, 186)}
  .fuchsia-background {background-color: rgb(156, 75, 134)}
  .gray {color: rgb(134, 127, 114)}
  .gray-background {background-color: rgb(90, 85, 75)}
  .green {color: rgb(82, 146, 50)}
  .green-background {background-color: rgb(73, 111, 54)}
  .lime {color: rgb(172, 217, 132)}
  .lime-background {background-color: rgb(99, 163, 73)}
  .maroon {color: rgb(169, 87, 38)}
  .maroon-background {background-color: rgb(97, 48, 40)}
  .navy {color: rgb(6, 11, 74)}
  .navy-background {background-color: rgb(41, 38, 77)}
  .olive {color: rgb(160, 147, 60)}
  .olive-background {background-color: rgb(139, 130, 69)}
  .purple {color: rgb(107, 57, 92)}
  .purple-background {background-color: rgb(107, 57, 92)}
  .red {color: rgb(223, 93, 93)}
  .red-background {background-color: rgb(139, 60, 49)}
  .silver {color: rgb(93, 82, 61)}
  .silver-background {background-color: rgb(73, 69, 61)}
  .teal {color: rgb(115, 142, 132)}
  .teal-background {background-color: rgb(83, 120, 106)}
  .white {color: rgb(255, 239, 214)}
  .white-background {background-color: rgb(185, 177, 164)}
  .yellow {color: rgb(255, 243, 152)}
  .yellow-background {background-color: rgb(208, 195, 97)}
}