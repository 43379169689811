// some misc styles
.element--center {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.menu {
	background-color: #f3f3f3;
	color: #333;
	border-radius: 2px;
	padding: 1px 5px;
}

.text-center {
	text-align: center;
}

.pull-right {
	float: right;
}

body.draft {
	background: repeating-linear-gradient(
									45deg,
									rgba(0, 0, 0, 0.03),
									rgba(0, 0, 0, 0.03) 1rem,
									rgba(0, 0, 0, 0.07) 1rem,
									rgba(0, 0, 0, 0.07) 2rem
	);
	& div.content.container article::before {
		content: "DRAFT";
		color: $red;
		margin-left: 80%;
		position: -webkit-sticky;
		position: sticky;
		top: -3px;
		z-index: 1000;
		background: $body-bg;
		border: $red solid 1px;
		border-radius: 4px;
		padding: 0.2rem;
		display: block; // position:sticky fix for safari
		text-align: center;
		width: 4rem;
	}
}


.github-edit {
  font-size: 0.7em;
  float: right;
}

header .github-edit {
  margin-top: -2.5em;
  margin-right: -2.5%;
}

footer .github-edit {
  margin-right: -2.5%;
  margin-top: 2.5em;
}